import Vue from 'vue'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  const arr = value.split(' ')
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('title', function (value, replacer = '_') {
  if (!value) return ''
  value = value.toString()

  const arr = value.split(replacer)
  const capitalized_array = []
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('truncate', function (value, limit) {
  return value.substring(0, limit)
})

Vue.filter('tailing', function (value, tail) {
  return value + tail
})

Vue.filter('datetime', function (value) {
  if (value) {
    value = String(value)
    const date = value.slice(8, 10).trim()
    const month = value.slice(4, 7).trim()
    const year = value.slice(11, 15)
    
    const time = new Date(Date.parse(value))
    let hours = time.getHours()
    const min = (time.getMinutes() < 10 ? '0' : '') + time.getMinutes()
    return `${date} ${month} ${year} ${hours}:${min}`
  }
})

Vue.filter('time', function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value))
    let hours = date.getHours()
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM'
      hours = hours % 12 || 12
      return `${hours}:${min} ${time}`
    }
    return `${hours}:${min}`
  }
})

Vue.filter('date', function (value, fullDate = false) {
  value = String(value)
  const date = value.slice(8, 10).trim()
  const month = value.slice(4, 7).trim()
  const year = value.slice(11, 15)

  if (!fullDate) return `${date} ${month}`
  else return `${date} ${month} ${year}`
})

function dateIndonesia (value) {
  if (!value) return '';
  let date
  if (Number.isInteger(value)) {
    date = new Date(value * 1000)
  } else {
    date = new Date(value)
  }

  const tahun = date.getFullYear();
  let bulan = date.getMonth();
  const tanggal = date.getDate();

  switch (bulan) {
    case 0: bulan = "Januari"; break;
    case 1: bulan = "Februari"; break;
    case 2: bulan = "Maret"; break;
    case 3: bulan = "April"; break;
    case 4: bulan = "Mei"; break;
    case 5: bulan = "Juni"; break;
    case 6: bulan = "Juli"; break;
    case 7: bulan = "Agustus"; break;
    case 8: bulan = "September"; break;
    case 9: bulan = "Oktober"; break;
    case 10: bulan = "November"; break;
    case 11: bulan = "Desember"; break;
  }

  return `${tanggal} ${bulan} ${tahun}`
}

Vue.filter('dateIndonesia', dateIndonesia)

Vue.filter('month', function (val, showYear = true) {
  val = String(val)

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./
  if (!showYear) {
    return regx.exec(val)[1]
  } else {
    return `${regx.exec(val)[1]} ${regx.exec(val)[2]}`
  }

})

Vue.filter('csv', function (value) {
  return value.join(', ')
})

Vue.filter('filter_tags', function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, '')
})

Vue.filter('k_formatter', function (num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.2$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.2$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.2$/, '') + 'K';
  }
  return num;
})

Vue.filter('nominal', function (angka) {
  let angkaString

  if (angka == null || angka == undefined || angka == '') {
    return 0
  }

  if (Number.isInteger(angka)) {
    angkaString = angka.toString()
  }  else {
    angkaString = angka
  }

  const number_string = angkaString.replace(/[^,\d]/g, '')
  const split = number_string.split(',')
  const sisa = split[0].length % 3
  let rupiah = split[0].substr(0, sisa)
  let ribuan = split[0].substr(sisa).match(/\d{3}/gi)

  if (ribuan) {
    const separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  return split[1] != undefined ? rupiah + ',' + split[1] : rupiah
})

Vue.filter('numberFormat', function (value) {
  const numUpd = new Intl.NumberFormat('id-ID')
  return numUpd.format(value)
})

Vue.filter('daysBetween', function (expired) {
  const ONE_DAY = 1000 * 60 * 60 * 24;
  const start = new Date()
  const end = new Date(expired)
  const differenceMs = Math.abs(start - end);
  return Math.round(differenceMs / ONE_DAY);
})

Vue.filter('timeAgo', function (time) {
  if (!time) return '';

  let date
  if (Number.isInteger(time)) {
    date = new Date(time * 1000)
  } else {
    date = new Date(time)
  }

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];

  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  if (Date.now() < date.getTime() || seconds > 172800) {
    return dateIndonesia(time)
  }
  const interval = intervals.find(i => i.seconds <= seconds);
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
})